:root {
  --orange: #ff8c00;
  --yellow: #FFD700;
  --white: #ffffff;
  --light-gray: #fff7dd;
  --dark-red: #8B0000;
  --purple: rgba(128, 0, 128, 0.3);
  --boxShadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.servicesz {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  position: relative;
  flex-direction: column;
  margin-bottom: 4rem;
  background-color: #ffefd5;
}

.headingz {
  font-size: 3rem;
  font-weight: bold;
  color: var(--dark-red);
  margin-bottom: 2rem;
  text-align: center;
}

.cardsz {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  width: 100%;
}

.blur {
  position: absolute;
  width: 22rem;
  height: 14rem;
  border-radius: 50%;
  z-index: -1;
}

.s-blur2z {
  top: 12rem;
  left: -8rem;
}

.cardz {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  border: 1px solid var(--orange);
  border-radius: 15px;
  background-color: var(--white);
  color: var(--dark-red);
  cursor: pointer;
  width: 100%;
  max-width: 250px;
  height: 300px;
  overflow: hidden;
  box-shadow: var(--boxShadow);
}

.card-emojez {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 1rem;
}

.card-headingz {
  font-size: 1.4rem;
  text-align: center;
  font-weight: bold;
  color: inherit;
  margin-bottom: 0.5rem;
}

.card-detailz {
  font-size: 1rem;
  text-align: center;
  color: inherit;
}

.cardz .c-buttonz {
  margin-top: 1rem;
  padding: 0.5rem 1.5rem;
  background-color: var(--dark-red);
  color: var(--white);
  border: none;
  border-radius: 10px;
  transition: background-color 0.3s ease;
}

.cardz .c-buttonz:hover {
  background-color: var(--orange);
}

.cardz:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.more-buttonz {
  margin-top: 2rem;
  padding: 0.8rem 2rem;
  background-color: var(--dark-red);
  color: var(--white);
  border: none;
  border-radius: 10px;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.more-buttonz:hover {
  background-color: var(--orange);
}

@media (max-width: 1200px) {
  .cardz {
    padding: 1rem;
  }
}

@media (max-width: 768px) {
  .card-emojez {
    width: 50px;
    height: 50px;
  }

  .card-headingz {
    font-size: 1.2rem;
  }

  .card-detailz {
    font-size: 0.9rem;
  }

  .cardz .c-buttonz {
    padding: 0.5rem 1rem;
  }
}

@media (max-width: 480px) {
  .cardsz {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .cardz {
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  .card-emojez {
    width: 50px;
    height: 50px;
  }

  .card-headingz {
    font-size: 1rem;
  }

  .card-detailz {
    font-size: 0.8rem;
  }

  .cardz .c-buttonz {
    padding: 0.3rem 0.8rem;
    font-size: 0.8rem;
  }

  .cardz:hover {
    transform: none;
    box-shadow: none;
  }
}
