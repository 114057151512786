@font-face {
  font-family: Gumela;
  src: url(../../fonts/Gumela.ttf);
}

:root {
  --orange: #ffa500;
  --white: #ffffff;
  --black: #4e4e4e;
  --gray: #808080;
  --dark-bg: #000000;
  --dark-text: #e0e0e0;
  --dark-hover: #333333;
}

.Intro {
  display: flex;
  height: 85vh;
  margin-top: 3.1rem;
  margin-bottom: 3rem;
}

.i-left {
  display: flex;
  position: relative; 
  flex: 1;
  flex-direction: column;
  gap: 3rem;
  z-index: 2;
}

.i-name {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.i-name > :nth-child(1) {
  color: #4e4e4e;
  font-size: 4rem;
  transition: opacity 1s ease-in-out;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

.i-name > :nth-child(2) {
  color: var(--orange);
  font-size: 3rem;
}

.i-details {
  display: flex;
  flex-direction: column;
  gap: 0.5rem; 
}

.i-details > span {
  display: flex;
  align-items: center;
  font-weight: 100;
  font-size: 17px;
  color: var(--black);
  margin-top: 10px;
}

.i-button {
  width: 9rem;
  height: 3rem;
  background-color: var(--orange);
  border: none;
  border-radius: 5px;
  color: var(--white);
  cursor: pointer;
  transition: background-color 0.3s;
}

.i-button:hover {
  background-color: darkorange;
}

.i-icons {
  display: flex;
  gap: 1.5rem;
  margin-top: 1rem;
}

.i-icons > a {
  text-decoration: none; /* Remove underline */
  transition: transform 0.3s, color 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--black);
}

.i-icons > a:hover {
  transform: scale(1.2);
  cursor: pointer;
  color: #FCA61F;
}

.i-icons > a span {
  margin-top: 0.5rem;
  color: #FCA61F;
}

.i-right {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.i-right > * {
  position: absolute;
  z-index: 1;
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.i-right > :nth-child(1) {
  animation: rotateAnimation 20s infinite linear;
  transform: scale(1);
}


@media screen and (max-width: 768px) {
  .Intro {
    flex-direction: column;
    gap: 3rem;
    height: auto;
  }

  .i-right {
    display: flex;
    justify-content: center;
  }

  .i-right > :nth-child(1) {
    transform: scale(0.8);
  }

  .i-right > :nth-child(2) {
    top: auto;
    right: auto;
    position: relative;
    transform: scale(0.3);
    margin-top: 2rem;
  }

  .i-icons {
    justify-content: center;
  }
}

@media screen and (max-width: 480px) {
  .Intro {
    gap: 4rem;
    height: auto;
    position: relative;
  }

  .i-left {
    order: 2;
    text-align: center;
    gap: 1.5rem;
  }

  .i-right {
    order: 1;
    transform: scale(1.1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0.1;
  }

  .i-name > :nth-child(1) {
    font-size: 3rem;
  }

  .i-name > :nth-child(2) {
    font-size: 2rem;
  }

  .i-details > span {
    font-size: 14px;
  }

  .i-icons {
    justify-content: center;
  }
}
