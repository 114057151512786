:root {
  --orange: #ffa500;
  --white: #ffffff;
  --black: #4e4e4e;
  --dark-text: #e0e0e0;
  --dark-hover: #4e4e4e;
}

.n-wrapper {
  box-shadow: 0px 0.5px var(--orange);
  height: 11vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: var(--white);
  z-index: 100;
  transition: background-color 0.3s, color 0.3s;
}

.n-wrapper.dark {
  background-color: var(--dark-bg);
  color: var(--dark-text);
}

.n-left {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.n-name {
  font-family: Gumela;
  font-size: 2rem;
  color: var(--black);
}

.n-right {
  display: flex;
  align-items: center;
}

.n-list {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
}

.n-list ul {
  display: flex;
  gap: 2.5rem;
}

.n-list li {
  position: relative;
  font-size: 2rem; /* Increased font size */
  font-weight: 500;
  cursor: pointer;
  color: var(--black);
}

.n-list li a {
  text-decoration: none;
  color: inherit;
  transition: color 0.3s;
  font-size: 1.3rem; /* Increased font size */
}

.n-list li:hover {
  color: var(--orange);
}

.n-list li::after {
  content: '';
  display: block;
  height: 2px;
  background: var(--orange);
  transition: width 0.3s;
  width: 0;
  position: absolute;
  bottom: -5px;
  left: 0;
}

.n-list li:hover::after {
  width: 100%;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger .line {
  width: 25px;
  height: 3px;
  background-color: var(--black);
  margin: 4px 0;
  transition: all 0.3s;
}

.hamburger .line.open {
  background-color: var(--orange);
}

@media screen and (max-width: 768px) {
  .n-list {
    display: none;
  }

  .hamburger {
    display: flex;
  }

  .n-list.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 10vh;
    right: 0;
    background-color: var(--white);
    width: 100%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    text-align: center;
  }

  .n-wrapper.dark .n-list.open {
    background-color: var(--dark-bg);
    color: var(--dark-text);
  }

  .n-wrapper.dark .hamburger .line {
    background-color: var(--dark-text);
  }

  .n-wrapper.dark .hamburger .line.open {
    background-color: var(--orange);
  }

  .n-name {
    font-size: 1.4rem; /* Adjust font size for smaller screens */
  }
}

@media screen and (max-width: 480px) {
  .n-wrapper{
    height: 8vh;
  }
  .n-name {
    font-size: 1.4rem;
  }

  .n-list.open {
    top: 10vh;
    width: 100%;
  }

  .n-list ul {
    flex-direction: column;
    gap: 1.2rem;
  }

  .n-list li {
    font-size: 1.2rem; /* Adjusted font size for smaller screens */
  }
}
