.contact-now {
    padding: 2rem;
    background-color: var(--background);
    color: #8B0000;
    text-align: center;
}

.contact-now h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.contact-now p {
    font-size: 1rem;
    margin-bottom: 2rem;
}

.contact-icons {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 2rem;
    flex-wrap: wrap;
}

.icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.icon-container:hover {
    transform: scale(1.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.icon {
    width: 50px;
    height: 50px;
}

.icon-container span {
    margin-top: 0.5rem;
    font-size: 1rem;
    color: orange;
}

/* Increase size for YouTube and Email icons */
.icon-youtube,
.icon-email {
    width: 60px;
    height: 60px;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}

.contact-form label {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    width: 100%;
    max-width: 500px;
}

.contact-form input,
.contact-form textarea {
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
}

.contact-form button {
    padding: 0.7rem 1.5rem;
    font-size: 1rem;
    background-color: #ffa500;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.contact-form button:hover {
    background-color: darkorange;
    transform: scale(1.05);
}

@media (max-width: 768px) {
    .contact-now h1 {
        font-size: 1.5rem;
    }

    .contact-now p {
        font-size: 0.9rem;
    }

    .contact-icons {
        gap: 1rem;
    }

    .icon-container {
        margin-bottom: 1rem;
    }

    .contact-form label {
        max-width: 100%;
    }
}

@media (max-width: 480px) {
    .contact-now {
        padding: 1rem;
    }

    .contact-now h1 {
        font-size: 2rem;
    }

    .contact-now p {
        font-size: 0.8rem;
    }

    .contact-icons {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        justify-items: center;
    }

    .icon-container {
        margin-bottom: 1rem;
    }

    .contact-form {
        width: 100%;
    }

    .contact-form label {
        max-width: 100%;
    }
}
