.experience {
  background-color: #ffefd5; /* Light yellow */
  padding: 50px;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.achievement {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #851613;
  margin: 20px;
}

.circle {
  background-color: #ffffff; /* White */
  color: #851613; /* Dark red */
  border: 3px solid #FFA500; /* Orange */
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.label1 {
  font-size: 18px;
  margin: 5px 0;
  color: #b20000; /* Orange */
}

.label2 {
  font-size: 18px;
  margin: 5px 0;
  color: #a90000; /* Yellow */
}

/* Media Queries */
@media (max-width: 768px) {
  .experience {
    flex-direction: column;
    padding: 20px;
  }

  .circle {
    width: 80px;
    height: 80px;
    font-size: 20px;
  }

  .label1,
  .label2 {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .circle {
    width: 60px;
    height: 60px;
    font-size: 18px;
  }

  .label1,
  .label2 {
    font-size: 14px;
  }
}
