@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Devanagari:wght@400;700&display=swap');

.blog-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #ffffff;
}

.blog {
  width: 100%;
  max-width: 750px;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 20px;
  background-color: #FDF5E6; /* Light background for contrast */
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}

.blog:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
}

.blog-heading {
  color: #8B0000; /* Dark Red */
  font-size: 26px;
  margin-bottom: 10px;
  z-index: 2;
  position: relative;
  display: flex;
  align-items: center;
}

.blog-detail {
  color: #4B0082; /* Indigo */
  font-size: 20px;
  line-height: 1.8;
  letter-spacing: 0.5px;
  white-space: pre-wrap;
  text-align: justify;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2; /* Standard property */
  box-orient: vertical; /* Standard property */
  transition: height 0.3s ease;
  z-index: 2;
  position: relative;
  font-family: 'Noto Sans Devanagari', sans-serif;
}

.blog-detail.expanded {
  -webkit-line-clamp: unset;
  line-clamp: unset;
  height: auto;
}

.learn-more-button {
  background: linear-gradient(180deg, #fdc50f 26.71%, #fb982f 99.36%);
  box-shadow: 0px 20px 24px 3px rgba(251, 161, 40, 0.42);
  border-radius: 34px;
  border: none;
  color: white;
  font-size: 16px;
  padding: 11px 26px;
  cursor: pointer;
  margin-top: 10px;
  transition: background 0.3s ease, border-color 0.3s ease, color 0.3s ease;
  z-index: 3;
  position: relative;
}

.learn-more-button:hover {
  background: white;
  border: 1px solid #fdc50f;
  color: #fdc50f;
}

.heading {
  font-size: 3rem;
  margin-bottom: 2rem;
  text-align: center;
  color: #650000; /* Dark Red */
}

@media (max-width: 768px) {
  .blog {
    width: 95%;
    margin-bottom: 20px;
  }
}
