:root {
  --light-yellow: #ffefd5;
  --purple: #6a0dad;
  --dark-red: #8B0000;
  --gold: #ffba4b;
  --boxShadow: 5px 5px 0 #ffb445;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.portfolio-wrapper {
  background-color: var(--light-yellow);
  padding: 10px;
  margin-top: 60px;
}

.portfolio-container {
  max-width: 1600px;
  margin: 0 auto;
}

.intro-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.intro-left-container {
  flex: 1;
  margin-right: 20px;
}

.intro-left-container iframe {
  width: 100%;
  height: 375px;
  box-shadow: none;
}

.intro-right-container {
  flex: 1;
  margin-left: 0px;
}

.intro-right-container .title {
  font-size: 3rem;
  color: var(--dark-red);
}

.intro-right-container .description {
  color:#000000;
  font-size: 1.4rem;
  line-height: 1.6;
  margin-bottom: 20px;
}

.intro-right-container .subtitle {
  font-size: 1.8rem;
  color: var(--gold);
}

.slider-title {
  font-size: 2rem;
  margin-bottom: 20px;
  color:#000000;
}

.single-video-display {
  width: 90%;
  margin: 0 auto;
}

.video-item {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  overflow: hidden;
  box-shadow: none;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
  margin-bottom: 0; /* Ensure no additional margin */
}

.video-item iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Ensures the iframe fills its container */
  pointer-events: none;
  border: none;
  transform: scale(1.2); /* Scale the iframe for larger appearance */
}

@media (max-width: 768px) {
  .intro-container {
    flex-direction: column;
  }

  .intro-left-container,
  .intro-right-container {
    margin: 0;
    width: 100%;
  }

  .intro-left-container iframe {
    height: 250px;
  }

  .intro-right-container .title {
    font-size: 2rem;
  }

  .intro-right-container .subtitle {
    font-size: 1.5rem;
  }
  .single-video-display {
    width: 100%;
    margin: 0 auto;
  }

  .slider-title {
    font-size: 1.5rem;
  }
}
