@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@500&display=swap');

:root {
  --primary-color: #b33c20;
  --background-color: #fff4ef;
  --card-background: #fff4ef;
  --text-color: #5b1a18;
  --border-color: #b33c20;
  --hover-color: #ff6347;
  --button-background: #b33c20;
  --button-hover: #ff6347;
  --card-hover-shadow: rgba(0, 0, 0, 0.2);
}

* {
  box-sizing: border-box;
}

.services-container-wrapper {
  padding: 2rem;
  background-color: var(--background-color);
  border: 2px solid var(--border-color);
  border-radius: 10px;
  box-shadow: 0 4px 8px var(--card-hover-shadow);
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
}

.services-container-wrapper h1 {
  color: var(--text-color);
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.services-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.service-card {
  display: flex;
  position: relative;
  padding: 1rem;
  border-radius: 10px;
  background-color: white;
  color: var(--text-color);
  width: 270px;
  height: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border: 2px solid var(--border-color);
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px var(--card-hover-shadow);
}

.service-content {
  display: flex;
  height: 100%;
  width: 100%;
}

.service-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  padding-right: 1rem;
}

.service-details {
  text-align: left;
}

.service-details h2 {
  font-size: 1.4rem;
  font-weight: bold;
  color: inherit;
}

.service-details p {
  font-size: 1.2rem;
  font-weight: bold;
  color: inherit;
}

.quick-contact {
  padding: 0.5rem 1rem;
  background-color: var(--button-background);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.quick-contact:hover {
  background-color: var(--button-hover);
}

.service-image {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.service-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.service-card:hover .service-image img {
  transform: scale(1.1);
}

@media (max-width: 1024px) {
  .services-container-wrapper h1 {
    font-size: 2rem;
  }

  .service-details h2 {
    font-size: 1.6rem;
  }

  .service-details p {
    font-size: 1.4rem;
  }

  .quick-contact {
    padding: 0.7rem 1.2rem;
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .services-container {
    flex-direction: column;
    align-items: center;
  }

  .service-card {
    width: 90%;
    height: 350px;
  }

  .service-image {
    width: 100%;
    margin-top: 1rem;
  }

  .service-info {
    width: 100%;
    align-items: center;
    text-align: center;
    padding-right: 0;
  }

  .service-details h2 {
    font-size: 1.8rem;
  }

  .service-details p {
    font-size: 1.6rem;
  }

  .quick-contact {
    align-self: center;
    padding: 0.8rem 1.4rem;
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .service-card {
    width: 100%;
    height: 240px;
  }

  .service-details h2 {
    font-size: 2rem;
  }

  .service-details p {
    font-size: 1.8rem;
  }

  .quick-contact {
    padding: 1rem 1rem;
    font-size: 1rem;
  }
}
