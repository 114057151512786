.site-vastu-visits,
.my-guruji {
  padding: 20px;
  margin: 20px 0; /* Add margin to avoid overlap with adjacent sections */
  text-align: center;
  background-color: #ffefd5;
}

.site-vastu-visits h1,
.my-guruji h1 {
  margin-bottom: 20px;
  font-size: 2.5em;
  color: #8B0000; /* Dark red color for headings */
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  justify-items: center;
  align-items: center;
}

.gallery-item {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.gallery-item img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.gallery-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 3px solid transparent;
  border-radius: 10px;
  transition: border-color 0.3s ease-in-out;
}

.gallery-item:hover::before {
  border-color: #ff7f50;
}

.gallery-item:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.gallery-item:hover .overlay {
  opacity: 1;
}

.icon {
  color: white;
}

@media (max-width: 768px) {
  .gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  .site-vastu-visits h1,
  .my-guruji h1 {
    font-size: 2em;
  }
}

@media (max-width: 480px) {
  .gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }

  .site-vastu-visits h1,
  .my-guruji h1 {
    font-size: 1.5em;
  }
}
