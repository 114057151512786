:root {
  --orange: #fca61f;
  --yellow: #ffeb3b;
}

.footer {
  background-color: #0d0d0d;
  color: rgb(255, 255, 255);
  padding: 3rem;
}

.f-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.f-sections {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;
}

.f-section {
  flex: 1;
  min-width: 250px;
}

.f-section h3 {
  color: var(--yellow);
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.f-section p,
.f-section ul,
.f-section li {
  margin: 0.5rem 0;
  font-size: 1rem;
}

.f-section ul {
  list-style: none;
  padding: 0;
}

.f-section ul li a {
  color: white;
  text-decoration: none;
  transition: color 0.3s;
  font-size: 1rem;
}

.f-section ul li a:hover {
  color: var(--orange);
}

.f-icons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.f-icons a {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
  text-decoration: none;
  transition: color 0.3s, transform 0.3s;
  font-size: 1rem;
}

.f-icons a:hover {
  color: var(--orange);
  transform: scale(1.1);
}

.map-container {
  width: 100%;
  height: 400px;
  margin-top: 2rem;
  position: relative;
}

.map-container iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

.f-bottom {
  text-align: center;
  margin-top: 2rem;
  font-size: 1rem;
}

.f-bottom a {
  color: var(--orange);
  text-decoration: none;
}

.f-bottom a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .f-content {
    align-items: center;
  }

  .f-sections {
    flex-direction: column;
    align-items: center;
  }

  .map-container {
    height: 300px;
  }

  .f-section {
    text-align: center;
  }

  .f-icons {
    justify-content: center;
  }
}

@media screen and (max-width: 480px) {
  .footer {
    padding: 2rem;
  }

  .f-section h3 {
    font-size: 1.25rem;
  }

  .f-section p,
  .f-section ul,
  .f-section li,
  .f-section ul li a,
  .f-icons a,
  .f-bottom {
    font-size: 0.875rem;
  }
}
