/* src/components/News/News.css */
@keyframes fadeInyy {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .news-sectionyy {
    background-color: #fff7dd;
    padding: 20px;
    border-radius: 8px;
    margin: 20px 0;
    font-size: 1.2rem;
    font-weight: 400;
    text-align: center;
    transition: all 0.3s ease-in-out;
    animation: fadeInyy 1s ease-in-out;
  }
  
  .news-sectionyy h2 {
    color: #8B0000;
    margin-bottom: 20px;
    font-size: 2.5rem;
    font-weight: 600;
    transition: color 0.3s ease-in-out;
  }
  
  .news-contentyy {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  
  .news-textyy {
    text-align: justify;
    max-width: 800px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    color: #8B0000;
    font-size: 1.1rem;
    transition: all 0.3s ease-in-out;
    animation: fadeInyy 1.5s ease-in-out;
  }
  
  .news-textyy h3 {
    color: black;
    margin-bottom: 15px;
    font-size: 1.8rem;
    font-weight: 500;
    transition: color 0.3s ease-in-out;
  }
  
  .news-textyy p {
    line-height: 1.5;
    margin: 10px 0;
    transition: all 0.3s ease-in-out;
  }
  
  .news-textyy p strong {
    color: #8B0000;
  }
  
  @media (max-width: 768px) {
    .news-sectionyy {
      padding: 15px;
      font-size: 1rem;
    }
  
    .news-sectionyy h2 {
      font-size: 2rem;
    }
  
    .news-contentyy {
      flex-direction: column;
    }
  
    .news-textyy h3 {
      font-size: 1.5rem;
    }
  
    .news-textyy p {
      font-size: 1rem;
    }
  }
  